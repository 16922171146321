<template>
  <div>
  <div class="content bg" :style="{ height: pageHeight }">
    <img class="hoticon" src="../assets/gsLogo.png" width="288px" height="99px">
    <div style="margin-bottom: 10px ; z-index: 1">
      <img src="../assets/indexTitle.png"  width="60%" >
    </div>
    <div class="module-box" style="height: 660px;width: 1420px;border-radius: 10%">
<!--      <img src="@/assets/bg.png" style="height: 660px;width: 61.8%; float: left">-->
      <div style="height: 456px;width: 680px; display: flex;justify-content: center;background-color: rgba(255,255,255,0.75);border-radius: 38px 38px 38px 38px">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="myForm" id="myF" label-width="50px" class="demo-ruleForm" style="color: white">
          <div style="margin-bottom: 30px;margin-top: 50px"><a style="font-size: 40px;color: #333 ;margin-right: 30px;
            text-decoration: none;
            position: relative;">登录</a><router-link to="/register" style="font-size: 32px;color: #666;text-decoration: none;">注册</router-link></div>

          <el-form-item prop="username" label-width="0px">
            <div style="font-size: 24px ; color: #666;text-align: left;">账号</div>
            <el-input style="width: 478px;color: white" type="text" v-model="ruleForm.username" placeholder="请输入账号" ></el-input>
          </el-form-item>

          <el-form-item prop="password" label-width="0px">
            <div style="font-size: 24px ; color: #666;text-align: left; margin-top: -16px">密码</div>
            <el-input style="width: 478px;color: white" type="password" v-model="ruleForm.password" placeholder="请输入密码"show-password ></el-input>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button  @click="submitForm('myForm')" style="width: 478px;background-color: #556BE7;color: white;border-radius: 10px 10px 10px 10px; font-size: 23px">登录</el-button>
          </el-form-item>
<!--          <div>-->
<!--            <router-link to="/register" style="color: white;margin-left: 370px;text-decoration: none;">注册</router-link>-->
<!--          </div>-->
        </el-form>

      </div>

    </div>
  </div>
  <a style="color: black;text-decoration: none" href="http://beian.miit.gov.cn/">@2019-2069 dekiot.cloud 版权所有ICP证：湘ICP备2023023187号</a>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {

    return {
      pageHeight: "",
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true,message: "请输入用户名称",trigger: "blur"}
        ],
        password: [
          { required: true,message:"请输入密码", trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   document.title = '德开物联展示平台'
    // })
    this.$nextTick(() => {
      // document.title = 'bridge-web'
    })
    var innerHeight = window.innerHeight;
    if (innerHeight > 790 && innerHeight < 872) {
      this.pageHeight = innerHeight - 105 + "px";
    } else {
      setTimeout(() => {
        this.pageHeight = innerHeight + "px";
      }, 100);
    }

    const that = this;
    window.onresize = function windowResize() {
      var innerHeight = window.innerHeight;
      if (innerHeight > 790 && innerHeight < 872) {
        that.pageHeight = innerHeight - 105 + "px";
      } else {
        setTimeout(() => {
          that.pageHeight = innerHeight + "px";
        }, 100);
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs.myForm.validate(valid => {
        // 提交表单
        if(valid){
          console.log("=====================================")
          this.subOne()
        }else{
          // this.$message({
          //   type: 'error',
          //   message: '登录失败！请检查输入'
          // })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    subOne(data) {
      let _this = this;
      axios.post('/api/auth/login', {'username': _this.ruleForm.username, 'password': _this.ruleForm.password})
          .then(response => {
            //处理成功响应
            if(response.data.code == "200"){
              this.$message({
                type: 'success',
                message: '登录成功'
              });
              axios.post('/api/auth/loginInfo',{
                'userId':response.data.data.userId,
                'ip':null,
                'loginType':"web"
              }).then(response => {

              })
              // console.log("userId = ",response.data.data)
              sessionStorage.setItem("userId", response.data.data.userId);
              // alert("username"+this.ruleForm.username);
              sessionStorage.setItem("username", this.ruleForm.username);
              // alert("登录成功")
              localStorage.setItem("token",response.data.data.userId)
              this.$router.push({name: 'showAll'});
            }else{
              this.$message({
                type: 'error',
                message: '登录失败！'+response.data.message
              });
            }

          })
          .catch(error => {
            console.log("error", error)
          })
    },
  }
}
</script>

<style scoped>
body{
  margin:0px
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background:url(@/assets/loginPage.png) no-repeat center center;
  background-size: cover;
  z-index: -1;
}
.content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /*设置蒙版的颜色和透明度*/
  /*z-index: -21;*/
}
.module-box{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.hoticon {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}
.alable{
  color: white;
}
</style>
