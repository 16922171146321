import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from "@/views/Login";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      title: 'ITMC应用展示平台',
      requiresAuth: false, // false表示不需要登录
    },
    component: Login
  },
  {
    path: '/showAll',
    name: 'showAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: 'ITMC应用展示平台',
      requiresAuth: true, // true表示需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ShowAll.vue')
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: 'ITMC应用展示平台',
      requiresAuth: false, // true表示需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/userManage',
    name: 'UserManage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: 'ITMC应用展示平台',
      requiresAuth: false, // true表示需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserManage.vue')
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: '德开物联展示平台',
  //     requiresAuth: false, // true表示需要登录
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
  // },
  // {
  //   path: '/test2',
  //   name: 'test2',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: '德开物联展示平台',
  //     requiresAuth: false, // true表示需要登录
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/test2.vue')
  // }
]

const router = new VueRouter({
  routes
})
// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  // console.log("title",document.title)
  // 通过requiresAuth判断当前路由导航是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = localStorage.getItem('token')
    // console.log("token",token)
    // 若需要登录访问，检查是否为登录状态
    if (!token) {
      next({
        path: '/',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})
export default router
